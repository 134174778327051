import './RequestForm.css';
import React, { useState } from 'react';
import Status from '../Shared/Status';
import { INQUIRY_TYPE_TO_REQUEST_TYPE, ORIGINAL_INQUIRY, ELIGIBILITY_SEARCH_QUERIES_ALL } from 'constants';
import { useEffect } from 'react';
import baseApi from 'axios-instance';
import ParseTime from '../Shared/ParseTime';
import { validateEligibilityJson } from 'transforms/backend_validations';
import RequestFormParser from './RequestFormParser';
import { transformEligibilityQueryResults, customDatesJsonStringParser } from './Transforms';
import { displayTableResults } from './TableResults';

const RequestForm = (props) => {

    const [eligibilitySearchJson, setEligibilitySearchJson] = useState(null);
    // initialize TableResults to an empty object.

    const [tableResults, setTableResults] = useState({ sections: [] });
    const [claimsDOS, setClaimsDOS] = useState(null);

    useEffect(() => {
        if (props.topic === "ELIGIBILITY_SEARCH" && props.status === "SUCCESS") {
            baseApi.get('/dashboard/data/eligibility_search/' + props.showRequestID, { responseType: 'text' }).then(response => {
                let data = JSON.parse(response.data, customDatesJsonStringParser);
                if (data.eligibility_query_results) {
                    setTableResults(transformEligibilityQueryResults(data.eligibility_query_results))
                }
            }).catch(error => {
                alert("Looks like the system has encountered an issue. Sorry about that. Please contact alan@healthharbor.co for assitance.");
                console.log(error);
            }).then(() => {
                // Fallback to legacy API if the new API fails.
                if (tableResults.sections.length === 0) {
                    baseApi.get('/dashboard/data/eligibility_search_legacy/' + props.showRequestID).then(response => {
                        if (response.data.eligibility_json) {
                            validateEligibilityJson(response.data.eligibility_json);
                            setEligibilitySearchJson(response.data.eligibility_json);
                        }
                    }).catch(error => {
                        alert("Looks like the system has encountered an issue. Sorry about that. Please contact alan@healthharbor.co for assistance.");
                        console.log(error);
                    });
                }
            });
        }

        if (props.topic === "CLAIMS") {
            if (props.requestDetails?.claims_date_of_service) {
                setClaimsDOS(ParseTime(props.requestDetails.claims_date_of_service));
            }
            // TODO: Figure out what format we want to use for claims data. In the meantime, we can use the legacy eligibility search json field.
            if (props.status === "SUCCESS") {
                baseApi.get('/dashboard/data/claims_status/' + props.showRequestID).then(response => {
                    if (response.data.claims_status_json) {
                        validateEligibilityJson(response.data.claims_status_json);
                        setEligibilitySearchJson(response.data.claims_status_json);
                    }
                }).catch(error => {
                    alert("Looks like the system has encountered an issue. Sorry about that. Please contact alan@healthharbor.co for assistance.");
                    console.error(error);
                });
            }
        }

        if (props.topic === "PRIOR_AUTH_STATUS") {
            // TODO: Figure out what format we want to use for prior_auth data. In the meantime, we can use the legacy eligibility search json field.
            if (props.status === "SUCCESS") {
                baseApi.get('/dashboard/data/prior_auth_status/' + props.showRequestID).then(response => {
                    if (response.data.prior_auth_status_json) {
                        validateEligibilityJson(response.data.prior_auth_status_json);
                        setEligibilitySearchJson(response.data.prior_auth_status_json);
                    }
                }).catch(error => {
                    alert("Looks like the system has encountered an issue. Sorry about that. Please contact alan@healthharbor.co for assistance.");
                    console.error(error);
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // For eligibility searches, pull the question label out of the inverted map. The map is quite small so we can iterate through it.
    let eligibility_search_question_labels = []
    if (props.requestDetails?.eligibility_search_queries?.length > 0) {
        props.requestDetails?.eligibility_search_queries.forEach((search_query) => {
            // Iterate through ELIGIBILITY_SEARCH_QUERIES and find the label for each value. Then add the label to the eligibility_search_question_label.
            for (let i = 0; i < ELIGIBILITY_SEARCH_QUERIES_ALL.length; i++) {
                if (ELIGIBILITY_SEARCH_QUERIES_ALL[i].value === search_query) {
                    eligibility_search_question_labels.push(ELIGIBILITY_SEARCH_QUERIES_ALL[i].label);
                }
            }
        })
    }
    const eligibility_search_question_labels_string = eligibility_search_question_labels.join(", ");

    return (
        <div className='RequestForm'>
            <div className='RequestForm-TitleWrapper'>

                <div className='RequestForm-SubTitle'>
                    <div className='RequestForm-Name'>{props.patientName}</div>
                    <div className='RequestForm-Topic'>{INQUIRY_TYPE_TO_REQUEST_TYPE[props.topic]}</div>
                    <Status status={props.status} />
                    {props.topic === "CLAIMS" && claimsDOS &&
                        <>
                            <div className='RequestForm-Spacer' />
                            <div className='RequestForm-AdditionalInfo'>{"DOS: " + claimsDOS[0] + ", " + claimsDOS[1]}</div>
                        </>
                    }
                    {
                        props.topic === "ELIGIBILITY_SEARCH" &&
                        <>
                            <div className='RequestForm-Spacer' />
                            <div className='RequestForm-AdditionalInfo'>
                                {eligibility_search_question_labels_string}
                            </div>
                        </>
                    }
                    <div className='RequestForm-Spacer' />
                    <div className='RequestForm-Time'>{props.time[0] + ", " + props.time[1] + ", " + props.time[2]}</div>
                </div>
            </div>
            <div className='RequestForm-Message'>
                <div className='RequestForm-SubTitle-Addition'>
                    {props.requestDetails &&
                        ORIGINAL_INQUIRY.map((item) => {
                            const inquiry_field_value = props.requestDetails?.[item.key];
                            // For Insurance in network, null is a valid value.
                            if (inquiry_field_value == null && !(item.key === 'insurance_in_network' && inquiry_field_value === null)) {
                                return ''
                            }
                            return (
                                <div className='RequestForm-Col'>
                                    <div className='RequestForm-Col-title'>
                                        {item.value}
                                    </div>
                                    <div className='RequestForm-Col-content'>
                                        {item.options ?
                                            item.options[inquiry_field_value]
                                            : inquiry_field_value
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className='RequestForm-Title'>{props.subject}</div>
            {displayTableResults(tableResults)}
            {eligibilitySearchJson && <RequestFormParser json={eligibilitySearchJson} />}
        </div>
    );
};

export default RequestForm;
