import './RequestHeader.css';
import Arrow from '../Assets/Request/LeftArrow.svg';
import PrintIcon from '../Assets/Request/Print-Icon.svg';
import { useDispatch } from 'react-redux';
import { REQUEST } from 'reducers';
import React from 'react';
import { Link } from 'react-router-dom';

type RequestHeaderProps = {
    inquiryId: string;
    showPrintButton: boolean;
}

function RequestHeader(props: RequestHeaderProps) {
    const { inquiryId, showPrintButton } = props;
    const dispatch = useDispatch();
    const printUrl = '/dashboard/data/eligibility_search/' + inquiryId + '/print'
    const printButton = showPrintButton ? <a href={printUrl} target="_blank" rel="noreferrer" className="RequestHeader-PrintLink"><img src={PrintIcon.toString()} className="RequestHeader-PrintIcon" alt="Print Icon"></img></a> : null;

    return (
        <div className='RequestHeader'>
            <Link to="/dashboard" onClick={() => { dispatch({ type: REQUEST.hide }); }}><img src={Arrow.toString()} className="RequestHeader-LeftArrow" alt="Back Arrow"></img></Link>
            <div className="RequestHeader-Spacer" />
            {printButton}
        </div>
    );
}

export default RequestHeader;
