import React from 'react';
import './Site.css';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import SiteTitle from './SiteTitle';
import HowItWorks from './HowItWorks';
import Security from './Security';
import Investors from './Investors';
import FinalCallToAction from './FinalCallToAction';
import Overview from './Overview';
import Testimonials from './Testimonials';


const Site: React.FC = () => {
    const calendly_url = 'https://calendly.com/health-harbor-alan/meeting';
    const openCalendly = () => {
        window.open(calendly_url, '_blank', 'noreferrer');
    };

    const pacific8_url = 'https://www.pac8.com/';
    const openPacific8 = () => {
        window.open(pacific8_url, '_blank', 'noreferrer');
    }

    const ycombinator_url = 'https://www.ycombinator.com/';
    const openYCombinator = () => {
        window.open(ycombinator_url, '_blank', 'noreferrer');
    }

    return (
        <div className='Site'>
            <SiteHeader openCalendly={openCalendly} />
            <SiteTitle openCalendly={openCalendly} />
            <Overview />
            <HowItWorks />
            <Security openCalendly={openCalendly} />
            <Testimonials />
            <Investors openPacific8={openPacific8} openYCombinator={openYCombinator} />
            <FinalCallToAction openCalendly={openCalendly} />
            <SiteFooter />
        </div>
    )
}

export default Site;
